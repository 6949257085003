.products-services {
  .heading {
    height: 162px;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 46px;
      font-weight: 500;
      line-height: 64px;
      color: $head_color;
    }
  }

  .content-container {
    position: relative;
    height: 829px;

    img {
      width: 100%;
      height: 100%;
    }

    .content-wrapper {
      width: 100%;
      height: 100%;
      padding: 0 20px 32px 20px;
      color: white;
      background-color: rgba(0, 0, 0, 0.5);

      .content {
        max-width: 1430px;
        height: 100%;
        margin-left: 10%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .content-title {
          h2 {
            min-height: 126px;
            max-width: 610px;
            margin-bottom: 5px;
            font-size: 42px;
            font-weight: 500;
            line-height: 63px;
          }

          p {
            max-width: 40vw;
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 30px;
          }

          .btn {
            width: 350px;
          }
        }

        .content-description {
          display: flex;
          justify-content: space-between;
          margin-top: 65px;

          .item-wrapper {
            width: 350px;

            .item {
              max-width: 315px;
              margin-top: 47px;
              cursor: pointer;

              h3 {
                font-size: 21px;
                font-weight: 700;
                line-height: 36px;
              }

              &:hover {
                opacity: 0.7;
              }
            }

            &.active {
              border-top: 2px solid $green_color;

              .item {
                margin-top: 45px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .products-services .content-container {
    .content-wrapper .content {
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 1150px) {
  .products-services .content-container {
    height: 1180px;
    .content-wrapper .content {
      .content-description {
        flex-direction: column;
        .item-wrapper {
          padding-right: 20px;
          .item h3 {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 160px) and (max-width: 800px) {
  .btn {
    height: auto;
  }
  .products-services {
    .heading {
      height: 96px;
      text-align: center;
      h2 {
        font-size: 24px;
        line-height: 36px;
      }
    }
    .content-container {
      height: 100%;
      background-size: 100% 100% !important;

      .content-wrapper {
        padding: 60px 20px;
        .content {
          .content-description {
            .item-wrapper {
              width: 100%;
            }

            .item-wrapper.active {
              border-top: none;
              opacity: 0.5;
            }

            .item-wrapper.active {
              border-top: none;
              opacity: 0.5;
            }
          }
          .content-title {
            padding-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 224px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .btn {
              max-width: 350px;
              width: 100%;
              height: auto;
              min-height: 50px;
            }


            p {
              width: 100%;
              max-width: 100%;
              margin: 20px 0;
              font-size: 14px;
              line-height: 21px;
            }

            h2 {
              font-size: 24px;
              line-height: 36px;
              min-height: auto;
            }
          }
        }
      }
    }
  }
}
