.input {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
  }

  input {
    height: 46px;
    padding: 10px 15px;
    border: 1px solid $input_border_color;
    border-radius: 5px;
    font-size: 14px;
    line-height: 26px;
    background-color: $background_color;
    outline: none;

    &::placeholder {
      font-size: 14px;
      line-height: 26px;
    }
  }

  &.input-error input {
    border: 1px solid red;
  }
}
