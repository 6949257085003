.about-company {
  padding: 85px 80px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: $secondary_color;

  &[data-home-page] {
    p {
      text-align: left;
    }
  }

  h2 {
    margin-bottom: 25px;
    text-align: center;
    line-height: 64px;
    font-size: 48px;
    font-weight: 500;
  }

  .hr {
    width: 150px;
    height: 5px;
    margin: 0 auto;
    background-color: $green_color;
  }

  p {
    margin-top: 50px;
    line-height: 30px;
    font-size: 16px;
    text-align: center;
    color: $text_color;
    max-width: 1024px;
  }
}

@media screen and (min-width: 160px) and (max-width: 800px) {
  .about-company#aboutus {
    padding: 40px 20px;

    h2 {
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;
    }

    p {
      font-size: 14px;
      line-height: 21px;
      text-align: justify;
      margin-top: 20px;
    }
  }
}
