.about-app {
  height: 488px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;

  .image-container {
    width: 540px;
    height: 338px;
    margin-right: 29px;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    position: relative;
  }

  .description-container {
    width: 540px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    p {
      margin-bottom: 30px;
      line-height: 30px;
      color: $text_color;
    }
  }

  .btn-container {
    width: 350px;
  }
}

@media screen and (min-width: 160px) and (max-width: 800px) {
  .about-app {
    min-height: auto;
    padding: 40px 20px;
    height: auto;
    flex-direction: column;

    .image-container {
      position: relative;
      max-width: 540px;
      width: auto;
      height: auto;
      margin: 0;
    }

    .description-container {
      width: 100%;

      p {
        font-size: 14px;
        line-height: 21px;
        text-align: justify;
        margin-bottom: 20px;
        margin-top: 20px;
      }

      .btn-container {
        width: 100%;
      }
    }
  }
}
