html,
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth !important;
}

a {
  text-decoration: none;
  color: inherit;
}

input,
textarea {
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
p,
a {
  margin: 0;
}

* {
  box-sizing: border-box;
}
