.dropdown {
  &-header {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-right: 8px;
      font-size: 16px;
      line-height: 24px;
      color: $text_color;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &-body {
    position: absolute;
    right: 470px;
    margin-top: 15px;
    padding: 4px 10px;
    border: 1px solid $dropdown_border_color;
    border-radius: 15px;
    background-color: white;
    z-index: 1;

    .item {
      padding: 4px 0;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(110, 121, 145, 0.5);
      }

      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      a {
        display: inline-block;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        color: $text_color;
      }
    }
  }
}
