.imprint {
  height: calc(100vh - 100px - 100px);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .information-container {
    display: grid;
    grid-template-rows: 64px 120px 60px 90px;
    padding: 0 5px;
    grid-gap: 30px;
    margin: 0 30px 0 165px;

    h1 {
      font-size: 46px;
      font-weight: 500;
      line-height: 64px;
    }

    p,
    a {
      font-size: 16px;
      line-height: 30px;
    }

    p {
      color: $text_color;
    }

    a {
      text-decoration: underline;
      color: $green_color;
    }
  }

  .map-container {
    height: calc(100vh - 200px);
    width: auto;
    max-width: auto;
    max-height: auto;
    > img,
    span,
    span > img {
      width: inherit !important;
      height: inherit !important;
      max-height: inherit !important;
      max-width: inherit !important;
      min-width: inherit !important;
      min-height: inherit !important;
      position: relative !important;
    }
  }
}

@media screen and (max-width: 1220px) {
  .imprint {
    .information-container {
      margin-left: 140px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .imprint {
    .information-container {
      margin-left: 50px;
    }
  }
}

@media screen and (max-width: 1050px) {
  .imprint {
    .information-container {
      margin-left: 30px;
      margin-right: 20px;
    }

    .map-container img {
      width: 686px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .imprint {
    height: auto;
    display: grid;
    grid-template-rows: repeat(2, 50%);
    justify-content: center;

    .information-container {
      margin: 0;
      text-align: center;
    }

    .map-container {
      padding: 20px;
      height: 100%;
      width: 100%;
      max-width: 720px;
      max-height: 860px;
      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .imprint {
    grid-template-rows: none;
    .information-container {
      margin-top: 10px;
      grid-gap: 15px;

      h1 {
        font-size: 40px;
      }

      p,
      a {
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .imprint {
    .information-container {
      grid-template-rows: 50px 95px 55px 75px;

      h1 {
        line-height: 50px;
        font-size: 37px;
      }

      p,
      a {
        font-size: 12px;
        line-height: 24px;
      }
    }

    .map-container {
      padding: 20px 10px 10px;
    }
  }
}

@media screen and (max-width: 360px) {
  .imprint {
    .information-container {
      grid-template-rows: 40px 88px 50px 66px;
      grid-gap: 10px;

      h1 {
        font-size: 32px;
        line-height: 40px;
      }

      p,
      a {
        line-height: 22px;
      }
    }
  }
}

@media screen and (max-width: 302px) {
  .imprint {
    height: calc(100vh - 100px - 100px);

    .information-container {
      grid-template-rows: 32px 88px 50px 66px;

      h1 {
        font-size: 28px;
        line-height: 32px;
      }

      p,
      a {
        font-size: 11px;
        line-height: 22px;
      }
    }
  }
}
