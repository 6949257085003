.get-interested {
  height: 234px;
  display: grid;
  grid-template-columns: 160px 757px;
  grid-gap: 45px;
  justify-content: center;
  align-items: center;
  background-color: $green_color;

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h3 {
    margin-bottom: 20px;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
  }
  span {
    font-size: 20px;
    line-height: 30px;
  }

  h3,
  span {
    color: white;
  }
}

@media screen and (min-width: 160px) and (max-width: 800px) {
  .get-interested {
    margin-top: -10px;
    display: block;
    min-height: 234px;
    height: auto;
    padding: 40px 20px;
    grid-template-columns: 160px 1fr;

    .icon-container {
      display: none;
    }

    h3 {
      font-size: 24px;
      line-height: 36px;
    }

    span {
      font-size: 14px;
      line-height: 21px;
      text-align: justify;
    }
  }
}
