.carousel {
  .carousel.carousel-slider {
    display: flex;
    flex-direction: column-reverse;

    .control-dots {
      position: static;
      margin-top: 15px;

      .dot {
        width: 15px;
        height: 15px;
        margin: 0 5px;
        border: 1px solid $green_color;
        box-shadow: none;

        &.selected {
          background-color: $green_color;
        }
      }
    }
  }
}

.carousel-item-image {
  position: relative !important;
  width: auto !important;
  height: auto !important;
  max-height: 325px !important;
  max-width: 50% !important;
}

.slide {
  > span {
    position: static !important;
  }
}

.description {
  display: flex;
  flex-direction: column;
}

.carousel-desription-item {
  max-width: 740px;
}
