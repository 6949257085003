.intro {
  height: 829px;

  video {
    position: absolute;
    width: 100%;
    height: 829px;
    object-fit: cover;
  }

  img {
    position: absolute;
    width: 100%;
    height: 829px;
  }

  .intro-content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 165px;
    background-color: rgba(0, 0, 0, 0.5);

    &[data-page-name="home-page"] {
      padding-left: 480px;
      background-color: transparent;
    }

    .description {
      margin-bottom: 20px;
      color: white;

      h1 {
        max-width: 708px;
        font-size: 46px;
        font-weight: 700;
        line-height: 64px;
        margin: 0 0 20px 0;
      }

      h2 {
        max-width: 680px;
        font-size: 16px;
        line-height: 30px;
        font-weight: 400;
        margin: 0 0 20px 0;
      }
    }

    .btn-container {
      width: max-content;
    }
  }
}

@media screen and (max-width: 1550px) {
  .intro {
    .intro-content[data-page-name="home-page"] {
      padding-left: 380px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .intro {
    .intro-content[data-page-name="home-page"] {
      padding-left: 300px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .intro {
    .intro-content[data-page-name="home-page"] {
      padding-left: 230px;
    }
  }
}

@media screen and (max-width: 1050px) {
  .intro {
    .intro-content[data-page-name="home-page"] {
      padding-left: 170px;
    }
  }
}

@media screen and (min-width: 160px) and (max-width: 800px) {
  .btn.btn-primary {
    height: auto;
    min-height: 50px;
    max-width: 350px;
  }
  .intro {
    // min-height: calc(100vh - 100px);
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      height: inherit;
    }
    .intro-content,
    .intro-content[data-page-name="home-page"] {
      padding: 40px 20px;
      width: 100%;

      .description {
        margin-top: 40px;
        h2 {
          font-size: 24px;
          line-height: 36px;
          text-shadow: rgb(0, 0, 0) 1px 0px 0px,
            rgb(0, 0, 0) 0.540302px 0.841471px 0px,
            rgb(0, 0, 0) -0.416147px 0.909297px 0px,
            rgb(0, 0, 0) -0.989992px 0.14112px 0px,
            rgb(0, 0, 0) -0.653644px -0.756802px 0px,
            rgb(0, 0, 0) 0.283662px -0.958924px 0px,
            rgb(0, 0, 0) 0.96017px -0.279415px 0px;
        }

        p {
          font-size: 14px;
          line-height: 21px;
          text-align: justify;
          text-shadow: rgb(0, 0, 0) 1px 0px 0px,
            rgb(0, 0, 0) 0.540302px 0.841471px 0px,
            rgb(0, 0, 0) -0.416147px 0.909297px 0px,
            rgb(0, 0, 0) -0.989992px 0.14112px 0px,
            rgb(0, 0, 0) -0.653644px -0.756802px 0px,
            rgb(0, 0, 0) 0.283662px -0.958924px 0px,
            rgb(0, 0, 0) 0.96017px -0.279415px 0px;
        }
      }

      .btn-container {
        width: 100%;
        max-width: 350px;
        margin-top: 20px;

        button {
          padding: 0;
        }
      }
    }
  }
}
