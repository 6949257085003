.collapse {
  border-bottom: 1px solid #dee2e6;

  &-header {
    padding: 20px 0;

    .header-wrapper {
      display: grid;
      grid-template-columns: 1fr 14px;
      gap: 33px;
      align-items: center;
      cursor: pointer;

      h3 {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:hover,
      &:hover .icon svg path {
        fill: $green_color;
        color: $green_color;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &-body {
    overflow: hidden;
    transition: height 0.35s ease;

    .body-wrapper {
      .additional-content {
        padding-left: 40px;
      }
    }
  }

  &.collapsed {
    .header-wrapper {
      color: $green_color;
    }
    .collapse-body {
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 800px) {
  .collapse {
    &-header {
      padding: 18px 0;
      .header-wrapper {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .collapse {
    &-header {
      padding: 15px 0;
      .header-wrapper {
        h3 {
          font-size: 17px;
        }
        .icon {
          display: flex;

          svg {
            width: 15px;
            height: 8px;
          }
        }
      }
    }
    &-body .body-wrapper {
      .additional-content {
        padding-left: 30px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .collapse {
    &-header {
      padding: 13px 0;
      .header-wrapper {
        gap: 30px;
        h3 {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .collapse {
    &-header {
      .header-wrapper {
        gap: 25px;
        h3 {
          font-size: 15px;
        }

        .icon svg {
          width: 14px;
          height: 7px;
        }
      }
    }
    &-body {
      .body-wrapper .additional-content {
        padding-left: 25px;
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .collapse {
    &-header {
      padding: 11px 0;
      .header-wrapper {
        h3 {
          font-size: 13px;
        }

        .icon svg {
          width: 13px;
          height: 6px;
        }
      }
    }
    &-body {
      .body-wrapper .additional-content {
        padding-left: 15px;
      }
    }
  }
}
