.home {
  .image-container {
    height: 611px;
    position: relative;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (min-width: 160px) and (max-width: 800px) {
  .home {
    .image-container {
      height: auto;
      position: relative;
      img {
        height: auto;
        width: 100%;
      }
    }
  }
}
