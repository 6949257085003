.charts {
  min-height: 854px;
  padding: 75px 20px;
  background-color: $secondary_color;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .performance-content {
    font-size: 18px;
    line-height: 24px;
    color: $text_color;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .carousel {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 10px;
    .content-wrapper {
      display: grid;
      grid-template-columns: 350px 1fr;
      align-items: center;

      > img,
      span:not(.carousel-desription-item),
      span > img {
        position: relative !important;
        width: 350px !important;
        min-width: auto !important;
        max-width: auto !important;
        min-height: auto !important;
        max-height: auto !important;
        height: 245px !important;
      }

      .description {
        margin-left: 30px;

        h2 {
          margin-bottom: 30px;
          font-size: 46px;
          font-weight: 500;
          line-height: 64px;
          text-align: left;
        }

        span {
          display: inline-block;
          font-size: 16px;
          line-height: 24px;
          color: $text_color;
          text-align: left;
        }
      }
    }

    .carousel.carousel-slider .control-dots {
      margin-top: 4px;
    }
  }

  .data-container {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 30px 20px;
    justify-content: center;

    .item {
      height: 195px;
      padding: 20px 15px;
      border-radius: 15px;
      background-color: white;
      cursor: pointer;

      .item-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        h4 {
          font-size: 32px;
          font-weight: 600;
          line-height: 38px;
        }

        p {
          width: 77px;
          font-weight: 500;
          line-height: 19px;
          font-size: 15px;
          text-align: right;
          color: $text_color;
        }
      }

      .item-information {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;

        .column-name {
          display: grid;
          grid-template-rows: repeat(3, 29px);
          grid-row-gap: 10px;
          align-items: center;
          max-width: 130px;

          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            color: $text_color;
          }
        }

        .column-data {
          display: grid;
          grid-template-rows: repeat(3, 29px);
          grid-row-gap: 10px;
          align-items: center;

          p {
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
          }

          .active {
            color: $green_color;
          }
        }
      }

      &:hover {
        background-color: $green_color;
        transition: background-color 0.15s ease-in-out;

        .item-head {
          h4,
          p {
            color: white;
            transition: color 0.15s ease-in-out;
          }
        }

        .item-information {
          .column-name p {
            color: rgba(255, 255, 255, 0.75);
            transition: color 0.15s ease-in-out;
          }

          .column-data p {
            color: white;
            transition: color 0.15s ease-in-out;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1325px) {
  .charts .data-container {
    grid-template-columns: repeat(5, 220px);

    .item .item-information {
      .column-name p {
        font-size: 15px;
      }

      .column-data p {
        font-size: 23px;
      }
    }
  }
}

@media screen and (max-width: 1325px) {
  .charts .data-container {
    grid-template-columns: repeat(5, 210px);
    .item .item-information {
      .column-name p {
        font-size: 14px;
      }

      .column-data p {
        font-size: 21px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .charts .data-container {
    grid-template-columns: repeat(5, 195px);

    .item .item-head {
      h4 {
        font-size: 30px;
      }
      p {
        width: 71px;
        font-size: 14px;
      }
    }

    .item .item-information {
      .column-name p {
        font-size: 13px;
      }

      .column-data p {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 1120px) {
  .charts .data-container {
    grid-template-columns: repeat(4, 185px);

    .item .item-information {
      .column-name p {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 1070px) {
  .charts .data-container {
    grid-template-columns: repeat(4, 175px);

    .item {
      .item-head {
        p {
          width: 70px;
          font-size: 13px;
        }
      }
      .item-information {
        .column-name p {
          font-size: 11px;
        }

        .column-data p {
          font-size: 17px;
        }
      }
    }
  }
}

@media screen and (min-width: 160px) and (max-width: 800px) {
  .charts {
    padding: 40px 20px;
    min-height: auto;

    .data-container {
      grid-template-columns: repeat(2, 170px);

      .item {
        height: auto;

        .item-information {
          .column-name {
            grid-row-gap: 5px;
          }

          .column-data {
            grid-row-gap: 5px;
          }
        }
      }
    }

    .carousel {
      .carousel-root {
        .content-wrapper {
          display: block;
          .description {
            padding-top: 20px;
            h2 {
              font-size: 28px;
              font-weight: 500;
              line-height: 36px;
            }

            span {
              padding-bottom: 20px;
              padding-right: 20px;
              font-size: 14px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 410px) {
  .charts {
    padding: 40px 20px;
    min-height: auto;

    .data-container {
      grid-template-columns: repeat(1, 210px);
    }

    .carousel {
      max-width: 1200px;
      margin: 0 auto;
      margin-bottom: 10px;
      .content-wrapper {
        display: grid;
        grid-template-columns: 350px 1fr;
        align-items: center;

        > img,
        span:not(.carousel-desription-item),
        span > img {
          max-width: 100% !important;
          max-height: auto !important;
          height: auto !important;
        }
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1125px) {
  .charts {
    .data-container {
      grid-template-columns: repeat(auto, 175px);
    }
  }
}
