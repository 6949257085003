.privacy {
  padding: 30px 165px;

  .collapse-container {
    margin-bottom: 10px;
  }

  h1 {
    margin-bottom: 30px;
    font-size: 46px;
    font-weight: 500;
    line-height: 64px;
  }

  h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #212529;
    margin-bottom: 15px;
  }

  p,
  ul,
  span {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 26px;
    color: #4a4a4a;
  }

  ul {
    margin-top: 0;
    padding-left: 40px;
    list-style: none;
  }
}

@media screen and (max-width: 1000px) {
  .privacy {
    padding: 30px 115px;

    h1 {
      font-size: 42px;
    }
  }
}

@media screen and (max-width: 800px) {
  .privacy {
    padding: 30px 85px;

    h1 {
      margin-bottom: 25px;
      line-height: 56px;
      font-size: 38px;
    }

    p,
    ul,
    span {
      font-size: 15px;
    }
  }
}

@media screen and (max-width: 700px) {
  .privacy {
    padding: 30px 70px;

    h1 {
      margin-bottom: 20px;
      line-height: 50px;
      font-size: 34px;
    }

    h4 {
      font-size: 16px;
    }

    p,
    ul,
    span {
      font-size: 14px;
      margin-bottom: 15px;
    }

    ul {
      padding-left: 30px;
    }
  }
}

@media screen and (max-width: 600px) {
  .privacy {
    padding: 30px 55px;
    h1 {
      line-height: 45px;
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 500px) {
  .privacy {
    padding: 30px 45px;
    h1 {
      margin-bottom: 15px;
      line-height: 38px;
      font-size: 26px;
    }
  }
}

@media screen and (max-width: 450px) {
  .privacy {
    h1 {
      font-size: 24px;
    }

    h4 {
      margin-bottom: 10px;
      font-size: 15px;
    }

    p,
    ul,
    span {
      margin-bottom: 10px;
      font-size: 13px;
    }

    ul {
      padding-left: 25px;
    }
  }
}

@media screen and (max-width: 400px) {
  .privacy {
    padding: 30px 40px;
    h1 {
      margin-bottom: 10px;
      line-height: 34px;
      font-size: 22px;
    }

    h4 {
      font-size: 14px;
    }

    p,
    ul,
    span {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 360px) {
  .privacy {
    padding: 25px 30px;
    h1 {
      font-size: 19px;
    }

    p,
    ul,
    span {
      line-height: 24px;
      font-size: 11px;
    }
  }
}

@media screen and (max-width: 300px) {
  .privacy {
    padding: 20px 25px;

    h1 {
      line-height: 30px;
      font-size: 18px;
    }

    h4 {
      font-size: 12px;
    }

    ul {
      padding-left: 15px;
    }
  }
}

@media screen and (max-width: 250px) {
  .privacy {
    padding: 15px 10px;

    h1 {
      line-height: 24px;
      font-size: 16px;
    }
  }
}
