.alert {
  .swal2-close:focus {
    box-shadow: none;
  }

  .swal2-actions {
    .swal2-confirm,
    .swal2-cancel {
      min-width: 195px;
      display: flex;
      align-items: center;
      border-radius: 16px;
      border: 2px solid $green_color;
      background: white !important;
      color: $green_color;

      &:focus {
        box-shadow: none;
      }
    }

    .swal2-cancel {
      background: $green_color !important;
      color: white;
    }
  }
}
