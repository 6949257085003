@import "styles/scss/colors.scss";

.header {
  height: 100px;
  padding: 0 29px 0 165px;
  transition: height 0.35s ease;

  &-wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr auto 60px;
    gap: 76px;
    align-items: center;

    .logo-wrapper {
      width: 120px;
      cursor: pointer;
    }

    .actions-container {
      display: grid;
      grid-template-columns: auto auto 66px 160px;
      gap: 28px;
      align-items: center;
      text-align: center;
      justify-content: flex-end;
    }

    .burger {
      display: none;

      cursor: pointer;

      .bar1,
      .bar2,
      .bar3 {
        width: 35px;
        height: 3px;
        background-color: #333;
        margin: 8px 0;
        transition: 0.4s;
      }
    }
  }

  &-wrapper,
  .collapse-body {
    .nav-item {
      font-size: 16px;
      line-height: 24px;
      color: $text_color;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .collapse-body {
    max-width: 500px;
    padding-bottom: 10px;
    transition: opacity 0.2s ease;
    opacity: 0;

    .collapse-actions-container {
      display: grid;
      grid-template-columns: repeat(2, max-content) minmax(150px, max-content) 60px;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;
    }
  }

  &.collapsed {
    .header-wrapper {
      .burger {
        .bar1 {
          transform: translate(0, 11px) rotate(-45deg);
        }
        .bar2 {
          opacity: 0;
        }
        .bar3 {
          transform: translate(0, -11px) rotate(45deg);
        }
      }
    }
    .collapse-body {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 1185px) {
  .header {
    padding-left: 130px;

    &-wrapper {
      gap: 56px;
    }
  }
}

@media screen and (max-width: 1120px) {
  .header {
    padding-left: 115px;

    &-wrapper {
      gap: 50px;
    }
  }
}

@media screen and (max-width: 1085px) {
  .header {
    padding-left: 80px;

    &-wrapper {
      gap: 25px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .header {
    display: grid;
    grid-template-rows: 100px 1fr;
    grid-gap: 20px;

    .header-wrapper {
      grid-template-columns: 1fr 35px;
      .actions-container,
      .dropdown-translation {
        display: none;
      }

      .burger {
        display: block;
      }
    }

    .collapse-body {
      .dropdown {
        &-body {
          left: 65px;
          right: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding-left: 50px;

    .collapse-body {
      max-width: 470px;

      .collapse-actions-container {
        .btn {
          padding: 0 15px;
        }
      }

      .dropdown {
        &-body {
          left: 35px;
          margin-right: 35px;
        }
      }
    }
  }
}

@media screen and (max-width: 525px) {
  .header {
    padding-left: 40px;

    .collapse-body {
      .collapse-actions-container {
        grid-template-columns: max-content max-content 130px 60px;

        .btn {
          padding: 0 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .header {
    .collapse-body {
      max-width: 450px;

      .collapse-actions-container {
        grid-template-columns: max-content max-content 120px 60px;

        .nav-item {
          font-size: 14px;
        }

        .btn {
          font-size: 17px;
        }
      }

      .dropdown .dropdown-header {
        span {
          font-size: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .header {
    .collapse-body {
      .collapse-actions-container {
        grid-template-columns: max-content max-content 100px 60px;

        .btn {
          padding: 0 3px;
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .header {
    padding: 0 20px;

    .collapse-body {
      .collapse-actions-container {
        grid-template-columns: max-content max-content 90px 60px;
        grid-gap: 3px;

        .btn {
          font-size: 15px;
        }
      }

      .dropdown {
        &-header span {
          font-size: 14px;
        }
        &-body {
          left: 5px;
          margin-right: 5px;

          .item a {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .header {
    &.collapsed {
      height: 300px !important;
    }

    .collapse-body {
      display: grid;
      grid-template-rows: 50px max-content;
      grid-gap: 15px;

      .collapse-actions-container {
        order: 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 25px;
        margin-bottom: 0;

        .nav-item {
          font-size: 16px;
        }

        .btn {
          width: 170px;
          font-size: 18px;
        }

        .dropdown-translation {
          width: 60px;
        }
      }

      .dropdown {
        display: flex;
        order: 1;
        .dropdown-header {
          span {
            font-size: 16px;
          }
        }
        .dropdown-body {
          margin-top: 45px;
        }
      }
    }
  }
}

@media screen and (max-width: 305px) {
  .header .collapse-body {
    .collapse-actions-container {
      .btn {
        width: 120px;
      }
    }
  }
}

@media screen and (max-width: 280px) {
  .header .collapse-body {
    .collapse-actions-container {
      .nav-item {
        font-size: 14px;
      }

      .btn {
        font-size: 16px;
      }

      .dropdown-translation {
        span {
          font-size: 14px;
        }
      }
    }

    .dropdown .dropdown-header {
      span {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 248px) {
  .header {
    padding: 0 10px;
    gap: 0px;

    &.collapsed {
      height: 280px !important;
    }

    .header-wrapper {
      grid-template-columns: 1fr 26px;

      .logo-wrapper,
      .logo-wrapper svg {
        width: 90px;
      }

      .burger {
        .bar1,
        .bar2,
        .bar3 {
          width: 26px;
        }
      }
    }

    .collapse-body {
      .collapse-actions-container {
        column-gap: 14px;
        .nav-item {
          font-size: 13px;
        }
        .btn {
          width: 95px;
          font-size: 15px;
        }
      }

      .dropdown {
        .dropdown-header span {
          font-size: 13px;
        }
        .dropdown-body .item a {
          font-size: 13px;
        }
      }
    }
  }
}

@media screen and (max-width: 200px) {
  .header {
    &.collapsed {
      height: 295px !important;
    }

    .collapse-body {
      grid-template-rows: 50px max-content;
      grid-gap: 10px;
      .collapse-actions-container {
        gap: 15px;

        .nav-item {
          width: 125px;
        }

        .btn {
          width: 80px;
          height: 45px;
          font-size: 13px;
        }

        .dropdown-translation {
          width: 45px;

          .dropdown-translation-header {
            padding: 7px 9px 7px 9px;

            span {
              font-size: 13px;
            }
          }
        }
      }

      .dropdown .dropdown-header {
        span {
          margin-right: 5px;
        }
      }
    }
  }
}
