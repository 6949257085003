.contacts {
  min-height: 820px;
  padding: 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;

  .form-container {
    padding: 50px 65px;
    background-color: white;

    h3 {
      margin-bottom: 30px;
      font-size: 46px;
      font-weight: 500;
      line-height: 64px;
    }

    .form-wrapper {
      display: grid;
      grid-template-columns: 171px minmax(150px, 730px);
      grid-gap: 79px;

      .form-contact-information {
        width: 170px;
        margin-top: 115px;
        display: flex;
        flex-direction: column;

        .contact-avatar {
          margin-bottom: 3px;
          height: 191px !important;
          position: relative !important;

          img,
          > img,
          span {
            position: relative !important;
            width: 160px !important;
            height: 191px !important;
            min-width: 160px !important;
            min-height: 191px !important;
          }
        }

        .contact-ansprechpartner {
          margin: 10px 0;
          font-size: 15px;
          line-height: 18px;
          color: $text_color;
          margin-left: 3px;
        }

        .contact-fullname {
          margin-bottom: 15px;
          font-size: 24px;
          font-weight: 600;
          line-height: 26px;
          margin-left: 3px;
        }

        .contact-email {
          font-size: 15px;
          line-height: 24px;
          color: $green_color;
          text-decoration: underline;
          margin-left: 3px;
        }
      }

      .form-personal-information {
        .form-fields {
          margin-bottom: 20px;
          display: grid;
          grid-template-columns: repeat(auto-fill, calc(50% - 15px));
          grid-column-gap: 30px;
          grid-row-gap: 20px;
        }
        textarea {
          width: 100%;
          max-width: 730px;
          height: 118px;
          padding: 12px 17px 12px 15px;
          margin-bottom: 13px;
          border: 1px solid $input_border_color;
          border-radius: 5px;
          font-size: 14px;
          line-height: 26px;
          background-color: $background_color;
          outline: none;
          resize: none;
        }
        .recaptcha-field {
          height: 63px;
          margin-bottom: 20px;
          transform: scale(0.81);
          transform-origin: 0 0;
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .contacts .form-container {
    .form-wrapper .form-personal-information {
      .form-fields .dropdown-form {
        .dropdown-form-body {
          margin-left: -45px;
          right: -25px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .contacts .form-container {
    .form-wrapper {
      grid-gap: 40px;
    }
  }
}

@media screen and (max-width: 750px) {
  .contacts .form-container {
    padding: 50px 40px;
    .form-wrapper {
      grid-gap: 40px;
    }
  }
}

@media screen and (max-width: 650px) {
  .contacts .form-container {
    .form-wrapper .form-personal-information {
      .form-fields {
        grid-template-columns: repeat(auto-fill, 100%);

        .dropdown-form .dropdown-form-body {
          margin: 0;
          right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .contacts .form-container {
    .form-wrapper .form-personal-information {
      .recaptcha-field {
        transform: scale(0.61);
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 535px) {
  .contacts .form-container {
    h3 {
      text-align: center;
    }
    .form-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-contact-information {
        align-items: center;
        margin: 0;
      }

      .form-personal-information {
        max-width: 62.5vw;
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .contacts .form-container {
    padding: 40px 20px;
    .form-wrapper .form-personal-information {
      .recaptcha-field {
        transform: scale(0.51);
      }
    }
  }
}

@media screen and (max-width: 250px) {
  .contacts .form-container {
    padding: 30px 10px;
  }
}

@media screen and (max-width: 230px) {
  .contacts .form-container {
    padding: 30px 30px;
    h3 {
      margin-bottom: 20px;
      line-height: 40px;
      font-size: 30px;
    }
    .form-wrapper {
      gap: 25px;
      .form-contact-information {
        width: 117px;

        .contact-avatar {
          img,
          > img {
            width: 160px !important;
            height: 191px !important;
            min-width: 160px !important;
            min-height: 191px !important;
          }
        }

        .contact-ansprechpartner {
          margin-bottom: 5px;
          line-height: 16px;
          font-size: 11px;
        }

        .contact-fullname {
          margin-bottom: 10px;
          line-height: 22px;
          font-size: 17px;
        }

        .contact-email {
          line-height: 20px;
          font-size: 11px;
        }
      }

      .form-personal-information {
        .recaptcha-field {
          transform: scale(0.4);
        }

        .form-fields {
          .input {
            label {
              font-size: 13px;
            }

            input,
            input::placeholder {
              font-size: 13px;
            }
          }

          .dropdown-form {
            label,
            .dropdown-form-header span {
              font-size: 13px;
            }
          }
        }

        textarea {
          font-size: 13px;
          &::placeholder {
            font-size: 13px;
          }
        }

        .btn {
          padding: 0 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 200px) {
  .contacts .form-container {
    padding: 30px 10px;
    .form-wrapper {
      .form-personal-information {
        .recaptcha-field {
          transform: scale(0.33);
        }
      }
    }
  }
}
