.dropdown-translation {
  span {
    font-size: 16px;
    line-height: 24px;
    color: $text_color;
  }

  &-header {
    position: relative;
    display: flex;
    column-gap: 6px;
    align-items: center;
    padding: 9px 11px 9px 13px;
    border: 1px solid $dropdown_border_color;
    border-radius: 38px;
    cursor: pointer;
  }

  &-body {
    position: absolute;
    width: 60px;
    padding: 8px 10px;
    margin-top: 5px;
    border: 1px solid $dropdown_border_color;
    border-radius: 15px;
    background-color: white;
    z-index: 1;

    .item {
      padding: 4px 0;
      text-align: center;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(110, 121, 145, 0.5);
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
