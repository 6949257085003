.footer {
  height: 100px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: white;
  background-color: $green_color;
  position: relative;
  width: 100%;

  .footer-wrapper {
    grid-template-columns: 249px calc(100% - 475px) 222px;
    display: grid;
    align-items: center;
    width: 100%;
    max-width: 1110px;
  }

  span {
    position: relative !important;
    margin: 0 auto 0 0 !important;
    cursor: pointer;
  }

  .additional-information-container {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 50px;
  }

  a:hover {
    opacity: 0.7;
  }
}

.linkedin-link {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.linkedin {
  position: relative !important;
  max-width: 36px !important;
  max-height: 36px !important;
  min-height: 36px !important;
  min-width: 36px !important;
}

@media screen and (max-width: 950px) {
  .footer {
    padding: 0 115px;
  }
}

@media screen and (max-width: 765px) {
  .footer {
    padding: 0 60px;
  }
}

@media screen and (max-width: 650px) {
  .footer {
    font-size: 14px;
    padding: 0 30px;
    .additional-information-container {
      grid-gap: 20px;
    }
  }
}

@media screen and (max-width: 475px) {
  .footer {
    padding: 0 15px;
    font-size: 13px;
    .additional-information-container {
      grid-gap: 15px;
    }
  }
}

@media screen and (max-width: 400px) {
  .footer {
    grid-template-columns: none;
    grid-template-rows: repeat(2, 50%);
    justify-content: center;
    font-size: 15px;

    .company-name-container {
      text-align: center;
    }
  }
}

@media screen and (max-width: 300px) {
  .footer {
    font-size: 14px;
  }
}

@media screen and (max-width: 220px) {
  .footer {
    font-size: 13px;
  }
}

@media screen and (max-width: 185px) {
  .footer {
    font-size: 11px;
  }
}
