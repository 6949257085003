.benefits {
  max-width: 1110px;
  padding: 75px 0;
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 15px;
  justify-content: center;

  .item {
    width: 350px;
    min-height: 105px;
    text-align: center;

    p {
      min-height: 30px;
      margin-top: 15px;
      color: $text_color;
      line-height: 30px;
      font-size: 16px;
    }
  }
}

@media screen and (min-width: 160px) and (max-width: 800px) {
  .benefits {
    padding: 40px 20px;
    row-gap: 20px;

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p {
        max-width: 300px;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}
