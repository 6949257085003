.btn {
  width: 100%;
  height: 50px;
  padding: 0 30px;
  border: 1px solid $green_color;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.btn-primary {
    color: white;
    background-color: $green_color;

    &:not([disabled]):hover {
      color: $green_color;
      background-color: white;
    }
  }

  &.btn-secondary {
    color: $green_color;
    background-color: white;

    &:not([disabled]):hover {
      color: white;
      background-color: $green_color;
    }
  }
}
