.dropdown-form {
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span {
    font-size: 14px;
    line-height: 26px;
    color: #6c6c6c;
  }

  .dropdown-form-header {
    position: relative;
    height: 46px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $input_border_color;
    border-radius: 5px;
    background-color: $background_color;
    cursor: pointer;

    span {
      padding-right: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .dropdown-form-body {
    position: absolute;
    top: 81px;
    max-width: 350px;
    border: 1px solid $input_border_color;
    border-radius: 5px;
    background-color: $background_color;
    z-index: 1;

    .item {
      padding: 10px 15px;
      cursor: pointer;

      &:not(:last-of-type) {
        border-bottom: 1px solid $input_border_color;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
